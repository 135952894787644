<template>
  <div class="org" v-loadingx="loading">
    <fm-title title-text="组织架构" :title-menus="[{key: 'add', label: '新增'}]"></fm-title>
    <div class="org-tree">
      <fm-tree :nodes="nodes" search is-selected @selected="onSelected" :contextmenu="contextmenu" @contextmenu="onContextmenu"></fm-tree>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      loading: false,
      chooseData: null
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    contextmenu () {
      return [{
        label: '新增',
        key: 'add'
      },
      {
        label: '修改',
        key: 'edit'
      },
      {
        label: '删除',
        key: 'del'
      }].map(v => v.label)
    },
    nodes () {
      let fn = (data) => {
        return {
          title: data.name,
          data: data
        }
      }
      let result = this.$store.getters.orgTree.map(fn)
      let each = (nodes) => {
        nodes.forEach(node => {
          node.children = node.data.child ? node.data.child.map(fn) : []
          each(node.children)
        })
      }
      each(result)
      return result
    }
  },
  methods: {
    async onContextmenu (menu, data) {
      console.log(menu, data)
    },
    onSelected (data) {
      this.chooseData = data.data.data
      this.$emit('chooseOrg', this.chooseData)
    },
    async loadData () {
      this.loading = true
      await this.$store.dispatch('loadOrgTree', true)
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.org {
  width: 100%;
  height: 100%;
  .org-tree {
    height: calc(100% - 48px);
    padding: 0 10px;
    overflow: auto;
  }
}
</style>