<template>
  <div class="org-home">
    <div class="left">
      <org @chooseOrg="chooseOrg"></org>
    </div>
    <div class="right">
      <div class="r-t">
        <role @chooseOrgRole="chooseOrgRole" :org="currentOrg"></role>
      </div>
      <div class="r-b">
        <user :org="currentOrg" :org-role="currentOrgRole"></user>
      </div>
    </div>
  </div>
</template>

<script>
import org from './home/org'
import role from './home/role'
import user from './home/user'

export default {
  components: {
    org,
    role,
    user
  },
  data () {
    return {
      currentOrg: null,
      currentOrgRole: null
    }
  },
  methods: {
    chooseOrg (data) {
      this.currentOrg = data
    },
    chooseOrgRole (data) {
      this.currentOrgRole = data
    }
  }
}
</script>

<style scoped lang="less">
  .org-home {
    width: calc(100% - 20px);
    margin: 10px;
    height: calc(100% - 20px);
    background: #fff;
    border-radius: 3px;
    display: flex;
    .left {
      width: 300px;
      border-right: 1px solid #dcdee2;
      margin-right: 10px;
      height: 100%;
    }
    .right {
      width: calc(100% - 310px);
      height: 100%;
      .r-t {
        width: 100%;
        height: 150px;
        border-bottom: 1px solid #dcdee2;
      }
      .r-b {
        width: 100%;
        height: calc(100% - 150px);
      }
    }
  }
</style>