<template>
  <div class="user" v-loadingx="loading">
    <fm-title :title-text="propOrgRole.orgId || propOrgRole.roleId ? '用户' + (propOrgRole.orgName || '') + '-' + (propOrgRole.roleName || '') : '全部用户'" :note-text="noteText" :title-menus="[{key: 'add', label: '新增用户'}]"></fm-title>
    <div class="user-list">
      <fm-table-new
        :simple-filter="true"
        :columns="columns"
        :auto-height="true"
        :handler-counter="countFun"
        @counterChange="countDataChange"
        :data-list="showDatas"
        :stripe="false"
        emptyPlaceholder="-">
        <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
      </fm-table-new>
    </div>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'

import {
  userRequest
} from '@/api'

export default {
  components: {
    TableActions
  },
  props: {
    org: {
      type: Object,
      default () {
        return null
      }
    },
    role: {
      type: Object,
      default () {
        return null
      }
    },
    orgRole: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      loading: false,
      chooseData: null,
      allDatas: [],
      noteText: ''
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    tableActions () {
      return []
    },
    propOrgRole () {
      let data = {
        orgId: null,
        orgName: null,
        roleId: null,
        roleName: null
      }
      if (this.org) {
        data.orgId = this.org.id
        data.orgName = this.org.name
      }

      if (this.role) {
        data.roleId = this.role.id
        data.roleName = this.role.name
      }
      
      if (this.orgRole) {
        data.orgId = this.orgRole.orgId
        data.orgName = this.orgRole.orgName
        data.roleId = this.orgRole.roleId
        data.roleName = this.orgRole.roleName
      }
      return data
    },
    showDatas () {
      let data = this.allDatas
      let filter = (orgId, roleId, orgRoles) => {
        return orgRoles.find(v => (!orgId || v.orgId === orgId) && (!roleId || v.roleId === roleId)) && true
      }
      if (this.propOrgRole.orgId || this.propOrgRole.roleId) {
        data = data.filter(v => filter(this.propOrgRole.orgId, this.propOrgRole.roleId, v.orgRoles))
      }
      return data
    },
    userStatusList () {
      return this.$store.getters.userStatusList
    },
    columns: {
      get () {
        return [{
          field: 'name',
          sort: true,
          title: '姓名'
        },
        {
          field: 'account',
          sort: true,
          title: '账号'
        },
        {
          field: 'phone',
          sort: true,
          title: '电话'
        },
        {
          field: 'idNo',
          sort: true,
          title: '身份证号'
        },
        {
          field: 'sortNum',
          sort: true,
          title: '排序值'
        },
        {
          field: 'statusText',
          sort: true,
          title: '状态',
          filters: this.userStatusList.map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        }]
      }
    }
  },
  methods: {
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    countFun (data) {
      return data.length
    },
    tableAction () {},
    async loadData () {
      this.loading = true
      this.allDatas = await userRequest.get({
        needRoleInfo: 1
      })
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.user {
  width: 100%;
  height: 100%;
  .user-list {
    height: calc(100% - 48px);
    width: 100%;
  }
}
</style>