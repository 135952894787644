<template>
  <div class="role" v-loadingx="loading">
    <fm-title :title-text="org ? '职务（' + org.name + '）' : '请选择组织架构'" :title-menus="org ? [{key: 'add_org_role', label: '添加职务'}] : []"></fm-title>
    <div class="role-list">
      <div class="role-item" @click="chooseRole(item)" v-for="item in showDatas" :key="item.id">
        <div class="role-name">{{item.roleName}}</div>
        <div class="btns">
          <div class="btn-item" :class="{'btn-item-b': $authFunsProxy.delRole}" v-if="$authFunsProxy.roleAuth">权限</div>
          <div class="btn-item" v-if="$authFunsProxy.delRole">删除</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  userOrgRoleRequest
} from '@/api'

export default {
  props: {
    org: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      loading: false,
      chooseData: null,
      allDatas: []
    }
  },
  created () {
    this.loadOrgData()
  },
  computed: {
    showDatas () {
      return this.org ? this.allDatas.filter(v => v.orgId === this.org.id) : []
    }
  },
  methods: {
    async loadOrgData () {
      this.loading = true
      this.allDatas = await userOrgRoleRequest.get({
        type: 'org_role'
      })
      this.loading = false
    },
    chooseRole (orgRole) {
      this.chooseData = orgRole
      this.$emit('chooseOrgRole', orgRole)
    }
  }
}
</script>

<style scoped lang="less">
.role {
  width: 100%;
  height: 100%;
  .role-list {
    height: calc(100% - 48px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .role-item {
    cursor: pointer;
    min-width: 100px;
    padding: 10px 10px 20px;
    position: relative;
    margin: 0 20px 20px 0;
    height: 80px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px  rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .role-item:hover {
    box-shadow: 2px 2px 4px  rgba(0,0,0,0.2);
    .btns {
      display: flex;
    }
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    border-top: 1px solid rgba(0,0,0,0.05);
    .btn-item {
      flex: 1;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
    }
    .btn-item-b {
      border-right: 1px solid rgba(0,0,0,0.05);
    }
  }
}
</style>